import { get, post } from '@/service/request';
import { dateFormat } from '@/filter';
import CommonService from '@/service/common';
import { PropertyEntityType } from '@/model/enum';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/BASystem`;
class BAIndexService {
    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const resFacilityCount = await get(`${URL_PATH}/facility/rts/count`);
        const resFacilityStatusCount = await get(`${URL_PATH}/facility/rts/countByState`);
        return {
            totalCount: resFacilityCount.totalCount,
            faultCount: resFacilityCount.faultCount,
            runingCount: resFacilityStatusCount.runingCount,
            stopCount: resFacilityStatusCount.stopCount
        };
    }

    /**
     * 正在告警数量
     * @returns
     */
    async getAlarmActiveCount() {
        const url = `${URL_PATH}/alarm/hts/ACTIVE/count`;
        const res = await post(url);
        return res;
    }

    /**
     * 运行时长统计(月)
     * @returns
     */
    async getFacilityRunTime(params?: any) {
        const url = `${URL_PATH}/facility/hts/runTimeStatistics`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['name', 'maxTime', 'deviation', 'avgTime'],
                rows: res
            },
            settings: {
                labelMap: { maxTime: '最高时长', deviation: '偏差值', avgTime: '平均时长' },
                type: 'histogram',
                showLine: ['deviation']
            }
        };
    }

    /**
     * 故障设备分类统计
     * @returns
     */
    async getFacilityFaultCount(params?: any) {
        const url = `${URL_PATH}/facility/rts/faultClassify`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['name', '故障数', '故障率'],
                rows: _.map(res, item => {
                    return {
                        name: item.name,
                        '故障数': item.faultCount,
                        '故障率': item.faultRate
                    };
                })
            },
            settings: {
                type: 'histogram',
                yAxisType: ['normal', 'percent'],
                // yAxisName: ['kWh', '故障率'],
                axisSite: { right: ['故障率'] },
                showLine: ['故障率']
            },
            extend: {
                yAxis: [
                    {
                        splitLine: {
                            lineStyle: {
                                type: 'solid'
                            }
                        }
                    },
                    {
                        show: false,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    }
                ]
            }
        };
    }

    /**
     * 近7天告警排名TOP5
     * @returns
     */
    async getLast7DayTop5AlarmCount(params: any) {
        const url = `${URL_PATH}/alarm/hts/rankByLocation`;
        const res = await post(url, params);
        return {
            columns: ['name', 'value'],
            rows: _.orderBy(_.get(res, 'children'), 'value')
        };
    }

    /**
     * 今天告警状态统计
     * @returns
     */
    async getAlarmStatusCount(params?: any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url, params);
        return {
            columns: ['name', '数量'],
            rows: [
                { name: '已处理', '数量': _.get(res, 'solvedCount') || 0 },
                { name: '未处理', '数量': _.get(res, 'unsolvedCount') || 0 }
            ]
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount() {
        const url = `${URL_PATH}/alarm/rts/countTrends`;
        const res = await get(url);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 告警级别统计
     * @returns
     */
    async getAlarmGradeCount() {
        const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        const url = `${URL_PATH}/alarm/rts/GRADE/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: _.map(_.keys(res), item => {
                return {
                    name: _.get(_.find(eventGradeList, eventGrade => eventGrade.code === item), 'name') || item,
                    value: res[item]
                };
            })
        };
    }
}

export default new BAIndexService();
