import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@/model/enum';
import BAIndexService from '../service/ba-index';
import { download } from '@/service/request';
import moment from 'moment';

export class BAFacilityRunTimeQueryModel extends QueryModel {
    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT,
        options: [
            { value: 'LAST_30_DAY', name: '近30天' },
            { value: 'LAST_90_DAY', name: '近90天' }
        ],
        span: 6,
        required: true
    })
    timeType: string = 'LAST_30_DAY';

    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.BA,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        hasAllOption: false,
        required: false
    })
    locationIds: string = undefined;

    toService() {
        const params: any = {
            // locationId: this.locationId,
            locationIds: this.locationIds,
            endTime: moment().add(1, 'day').startOf('day').toDate().getTime()
        };
        switch (this.timeType) {
            case 'LAST_30_DAY':
                params.startTime = moment().add(-30, 'day').toDate().getTime();
                break;
            case 'LAST_90_DAY':
                params.startTime = moment().add(-90, 'day').toDate().getTime();
                break;
        }
        return params;
    }
}

export async function BAFacilityRunTimeService(model: BAFacilityRunTimeQueryModel) {
    const res = await BAIndexService.getFacilityRunTime(model?.toService());
    const chartOptions = {
        title: '运行时长统计',
        data: res.data,
        settings: res.settings,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '设备类型',
                dataIndex: 'name'
            },
            {
                title: '平均时长',
                dataIndex: 'avgTime'
            },
            {
                title: '最高时长',
                dataIndex: 'maxTime'
            },
            {
                title: '偏差值',
                dataIndex: 'deviation'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function BAFacilityRunTimeExportService(queryModel: BAFacilityRunTimeQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/export/runTimeStatistics`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
