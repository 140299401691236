import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@/model/enum';
import BAIndexService from '../service/ba-index';
import { download } from '@/service/request';
import { percentFormat } from '@/filter';

export class BAFacilityFaultQueryModel extends QueryModel {
    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: [
            { value: 'LAST_30_DAY', name: '故障数' },
            { value: 'LAST_90_DAY', name: '故障率' }
        ],
        span: 6,
        required: true
    })
    type: string = 'LAST_30_DAY';

    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.BA,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        hasAllOption: false,
        required: false
    })
    locationIds: Array<string> = undefined;

    toService() {
        return {
            locationIds: this.locationIds,
            type: this.type
        };
    }
}

export async function BAFacilityFaultService(model: BAFacilityFaultQueryModel) {
    const res = await BAIndexService.getFacilityFaultCount(model?.toService());
    const chartOptions = {
        title: '故障设备分类统计',
        data: res.data,
        settings: res.settings,
        extend: res.extend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '设备类型',
                dataIndex: 'name'
            },
            {
                title: '故障数',
                dataIndex: '故障数'
            },
            {
                title: '故障率',
                dataIndex: '故障率',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function BAFacilityFaultExportService(queryModel: BAFacilityFaultQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/export/faultClassify`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
